import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';

import { init } from './errors/tracking/init.client';

init();

prepareApp().then(() => {
  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <RemixBrowser />
      </StrictMode>,
    );
  });
});

async function prepareApp() {
  if (import.meta.env.VITE_ENABLE_API_MOCKS === 'true') {
    const { worker, setupMSWGlobals, onUnhandledRequest } = await import('./mocks/browser.client');
    setupMSWGlobals();
    return worker.start({ onUnhandledRequest });
  }

  return Promise.resolve();
}
