import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { replayIntegration } from '@sentry/remix';
import { useEffect } from 'react';

import { getPublicEnv } from '~/environment/getPublicEnv';
import { dsn } from '~/errors/tracking/config';

export function init() {
  Sentry.init({
    enabled: getPublicEnv('PUBLIC_ENABLE_SENTRY') === 'true',
    dsn,
    environment: getPublicEnv('PUBLIC_ENVIRONMENT'),
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // See https://github.com/getsentry/sentry-javascript/issues/9728
      replayIntegration(),
    ],

    // Performance Monitoring
    tracesSampleRate: getPublicEnv('PUBLIC_ENVIRONMENT') === 'production' ? 0.1 : 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 0%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    denyUrls: [
      /^moz-extension:\/\//,
      /^chrome:\/\/extensions/,
      /^chrome-extension:\/\//,
      /^https?:\/\/js\.intercomcdn\.com/,
    ],
  });
}
